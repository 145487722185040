<template>
	<div class="User">
		
		<div class="Top">
			<div class="Left">
				快递公司详情
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回列表</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="One">
				
				<li>
					<span>快递名称</span>
					<em>
						<el-input v-model="Company.Name" style="width: 200px;"></el-input>
					</em>
				</li>
				
				<li>
					<span>快递代码</span>
					<em>
						<el-input v-model="Company.Code" style="width: 200px;"></el-input>
					</em>
				</li>
				
				<li>
					<span>类型</span>
					<em>
						<el-select placeholder="设置分类状态" v-model="Company.Type">
							<el-option label="国内运输商" value="国内运输商"></el-option>
						    <el-option label="国际运输商" value="国际运输商"></el-option>
							<el-option label="国际邮政" value="国际邮政"></el-option>
					    </el-select>
					</em>
				</li>
				
				
				<li>
					<span></span>
					<em>
						<el-button @click="UpdateCompany()" type="danger">
							提交信息
						</el-button>
					</em>
				</li>
				
				
			</div>
		</div>
	
		
	</div>
</template>

<script>
	import {Select,Option,Dialog} from 'element-ui'
	export default {
	  name: 'ExpressCompanyInfo',
	  props: {
	  },
	  data() {
	      return {
			  Company:{
				  Name:'',
				  Code:'',
				  Type:'国内运输商',
			  },
	      }
	  },
	  components: {
		'el-select':Select,
		'el-option':Option,
		'el-dialog':Dialog
	  },
	  created() {
	  	if(this.$route.params.Id == undefined){
			this.$message('缺少分类身份ID，页面无法工作')
			return
		}
		this.Company.Code = this.$route.params.Id
		if(this.Company.Code != "add"){
			this.GetCompany(this.$route.params.Id)
		}
		if(this.Company.Code == "add"){
			this.Company.Code = ''
		}
	  },
	  methods:{
		    GetCompany(_userId){
		  		let data = {Service:'Help',Class: 'ExpressCompany',Action: 'Get',Code:this.Company.Code,}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){this.$message(res.Msg);return;}
		  			this.Company = res.Data
		  			
		  		})
		    },
			UpdateCompany(){
				let data = {Service:'Help',Class: 'ExpressCompany',Action: 'Update',Name:this.Company.Name,Code:this.Company.Code,Type:this.Company.Type,}
				if(this.$route.params.Id == 'add'){
					data.Action = "Add"
				}
				this.$post(this.$store.getters.getApiHost,data)
				.then((res) => {
					this.$message(res.Msg)
					if(res.ErrorId == 0){
						this.$Jump('/do/express_company/list')
					}
				})
			},
	  }
	}
</script>

<style scoped>
.User{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.User .Top{
	display: flex;
	align-items: center;
}
.User .Top .Left{
	
}
.User .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.User .List{
	margin-top: 20px;	
}
.User .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.User .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.User .List .One li span{
	width: 140px;
	color: rgba(0,0,0,0.3);
}
.User .List .One li  em{
	flex: 1;
}
.User .List .One li i{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}

.ReSetPas{
	
}
.ReSetPas li{
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	padding-right: 80px;
}
.ReSetPas li span{
	width: 140px;
	text-align: right;
	padding-right: 10px;
}
</style>
